import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { initReactI18next } from 'react-i18next';
import i18n from "i18next";
import translation from "./translate/translation"

const root = ReactDOM.createRoot(document.getElementById('root'));
i18n.use(initReactI18next)
.init({
    resources:translation,
    fallbackLng:"en"
})
root.render(
    <App />
);


