import React, { useState } from "react";
import classes from "./style/slider.module.css"

function DateInput() {
  // Initialize state for the selected date
  const [selectedDate, setSelectedDate] = useState("");

  // Handle the date change
  const handleDateChange = (event) => {
    const inputDate = event.target.value;
    const currentDate = new Date();
    const selectedDate = new Date(inputDate);

    // Check if the selected date is in the past
    if (selectedDate < currentDate) {
      // If it's in the past, don't update the state
      // You can display an error message or handle it in any way you prefer
      alert("You cannot choose a date that has passed.");
    } else {
      // Update the state with the selected date
      setSelectedDate(inputDate);
    }
  };

  return (
    <div className={classes.calnder_container}>
      <input
        value={selectedDate}
        onChange={handleDateChange}
        className={classes.calnder}
        type="date"
        name=""
        placeholder="Date"
      />
    </div>
  );
}

export default DateInput;
