import React from "react";
import classes from "./style/chatbot.module.css"
const options = [
  {
    value: "1",
    label: "Dr. Khulood Saleh",
  },
  {
    value: "3",
    label: "Dr. Amr Hassan",
  },
  {
    value: "4",
    label: "Dr. Tarek Saleh",
  },
];

function ChatForm() {
  return (
    <div>
   
      <form>
        <div className={classes.divchatbot}>
            <input className={classes.inputchatbot} type="text" name="" placeholder="Name"/>
        </div>
        <div className={classes.divchatbot}>
            <input className={classes.inputchatbot} type="number" name="" placeholder="Number"/>
        </div>
        <div className={classes.divchatbot}>
            <input className={classes.inputchatbot} type="date" name="" placeholder="Date"/>
        </div>
        <select className={classes.inputchatbot}>
            {
                options.map(option =>(
                 <option key={option.name} value={option.value}>{option.label}</option>
  
                ))
            }
        </select>
        <div className={classes.divchatbot}>
            <button className={classes.submitchatbot} >submit</button>
        </div>
      </form>
    </div>
  );
}

export default ChatForm;
